<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-09 14:16:22
 * @LastEditTime: 2022-10-11 17:40:27
 * @LastEditors: cyy
 * @Description: 二维码设置
 * @FilePath: \teacher.dingdingkaike\src\views\graphic\components\ewmSetting.vue
-->
<template>
  <div class="ewmSetting">
    <el-dialog
      title="设置二维码"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          label-position="left"
        >
          <el-form-item label="引导语">
            <el-input
              v-model="form.content"
              placeholder="请输入引导语"
              show-word-limit
              maxlength="30"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传二维码">
            <div class="coustom_contain">
              <div class="tu">
                <img v-if="form.qr_code_url" :src="form.qr_code_url" alt="" />
              </div>
              <div class="right">
                <div class="tip">
                  建议尺寸400*400px，JPG、PNG格式，图片小于2M
                </div>
                <el-button size="medium" style="width: 100px" @click="upload">
                  更换封面
                </el-button>
              </div>
            </div>
            <changePhotoimg
              :isshow="true"
              ref="changePhotoimg"
              @complete="complete"
              class="changePhotoimg"
              :size="2"
            ></changePhotoimg>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import changePhotoimg from '@cm/base/changePhotoimg'
export default {
  name: 'ewmSetting',

  data() {
    return {
      form: {
        status: '2',
        content: '',
        qr_code_url: '',
      },
    }
  },

  created() {
    this.form = _.merge({}, this.course_guide_content)
    this.getDefauleCode()
  },

  components: {
    changePhotoimg,
  },

  props: {
    course_guide_content: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async getDefauleCode() {
      const { data } = await this.$http({
        url: '/Organ/getDefaultCode',
      })
      if (!this.form.qr_code_url) {
        this.form.qr_code_url = data
      }
    },

    submit() {
      this.$emit('ewmVal', this.form)
      this.cancel()
    },

    // 图片结果
    complete(val) {
      this.form.qr_code_url = val
    },

    // 选择图片
    upload() {
      this.$refs.changePhotoimg.changePicture()
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.ewmSetting {
  .result-pic {
    width: 120px;
    height: 120px;
    margin-right: 30px;
  }
  .changePhotoimg {
    height: 0 !important;
    width: 0 !important;
  }

  .coustom_contain {
    display: flex;
    .tu {
      width: 120px;
      height: 120px;
      background: #f5f5f5;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      .tip {
        font-size: 14px;
        color: #333333;
        margin-bottom: 20px;
        line-height: 1.5;
      }
    }
  }
}
</style>
