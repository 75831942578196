<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-08-30 18:20:12
 * @LastEditTime: 2022-10-19 18:43:57
 * @LastEditors: cyy
 * @Description: 新建/编辑图文
 * @FilePath: \teacherdingding.com\src\views\graphic\components\newGraphic.vue
-->
<template>
  <div class="newGraphic">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="140px"
      class="ruleFormclass"
      label-position="left"
    >
      <el-form-item label="图文名称" :required="true">
        <el-input
          class="name_input"
          v-model="ruleForm.name"
          @blur="ruleForm.name = ruleForm.name.replace(/^\s+|\s+$/gm, '')"
          style="width: 500px"
          size="medium"
          placeholder="输入图文名称"
          show-word-limit
          maxlength="100"
        ></el-input>
      </el-form-item>

      <el-form-item label="图文封面" :required="true">
        <div class="coustom_contain">
          <div class="tu">
            <img v-if="ruleForm.cover_img" :src="ruleForm.cover_img" alt="" />
          </div>
          <div class="right">
            <div class="tip">建议尺寸800*450px，JPG、PNG格式，图片小于5M</div>
            <el-button size="medium" style="width: 100px" @click="upload">
              更换封面
            </el-button>
          </div>
        </div>
        <changePhotoimg
          :isshow="true"
          ref="changePhotoimg"
          @complete="complete"
          class="changePhotoimg"
          :size="5"
        ></changePhotoimg>
      </el-form-item>

      <el-form-item label="图文详情" :required="true">
        <template slot="label">
          <span class="text-nowarp">
            图文详情（已报名学员在h5详情页看到的内容）
            <!-- <helpIcon
              prompt="已报名学员在h5详情页看到的内容"
            ></helpIcon> -->
          </span>
        </template>
        <editor class="editor" style="padding-top: 40px;" v-model="ruleForm.details"></editor>
      </el-form-item>

      <el-form-item label="试看内容" class="norequire">
        <template slot="label">
          <span class="text-nowarp">
            试看内容（未报名的学员在h5详情页可以观看到的内容，未填写显示空）
            <!-- <helpIcon
              prompt="未报名的学员在h5详情页可以观看到的内容，未填写显示空"
            ></helpIcon> -->
          </span>
        </template>
        <editor class="editor" style="padding-top: 40px;" v-model="ruleForm.trial_details"></editor>
      </el-form-item>

      <el-form-item label="售卖方式" class="norequire">
        <div class="sale_contain">
          <el-radio-group v-model="ruleForm.price_type">
            <el-radio label="2">免费</el-radio>
            <el-radio label="3">密码</el-radio>
            <el-radio label="1">付费（大于100元可开启分期支付）</el-radio>
          </el-radio-group>
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="100px"
            class="ruleFormclass"
            label-position="left"
          >
            <el-form-item
              label="报名密码"
              :required="true"
              v-if="ruleForm.price_type == 3"
            >
              <el-input
                class="name_input"
                v-model="ruleForm.fee_pwd"
                @input="
                  ruleForm.fee_pwd = ruleForm.fee_pwd.replace(/[\W]/g, '')
                "
                style="width: 300px"
                size="medium"
                placeholder="请输入8位数以内的数字或字母密码"
                maxlength="8"
              ></el-input>
            </el-form-item>

            <template v-if="ruleForm.price_type == 1">
              <el-form-item label="价格" :required="true">
                <el-input
                  class="name_input"
                  :value="ruleForm.price"
                  style="width: 300px"
                  @input="ruleForm.price = toNumber(arguments[0])"
                  @blur="getPriceValue"
                  ref="price"
                  size="medium"
                  placeholder="请输入价格"
                ></el-input>
              </el-form-item>

              <el-form-item label="划线价" class="norequire">
                <el-input
                  class="name_input"
                  :value="ruleForm.cost_price"
                  style="width: 300px"
                  @input="ruleForm.cost_price = toNumber(arguments[0])"
                  @blur="getCostPriceValue"
                  ref="costprice"
                  size="medium"
                  placeholder="请输入划线价，选填"
                ></el-input>
                <huabeiIssue
                  style="padding: 0px 0 20px 80px; margin-top: 8px"
                  :price="ruleForm.price"
                  :is_installment_3.sync="ruleForm.is_installment_3"
                  :is_installment_6.sync="ruleForm.is_installment_6"
                  :is_installment_12.sync="ruleForm.is_installment_12"
                ></huabeiIssue>
              </el-form-item>
            </template>

            <el-form-item label="图文有效期" class="norequire">
              <el-radio-group
                v-model="ruleForm.validity_type"
                style="margin-top: 14px"
              >
                <p>
                  <el-radio label="1">永久有效</el-radio>
                </p>
                <p class="mt20 mb20">
                  <el-radio label="2" :disabled="ruleForm.price_type == 2">
                    购买后
                    <el-input-number
                      size="medium"
                      :disabled="
                        ruleForm.price_type == 2 || ruleForm.validity_type != 2
                      "
                      v-model="ruleForm.day"
                      controls-position="right"
                      :min="1"
                      :max="9999"
                      style="width: 158px"
                      class="ml10 mr10"
                    ></el-input-number>
                    天有效
                  </el-radio>
                </p>
                <p>
                  <el-radio label="3" :disabled="ruleForm.price_type == 2">
                    固定时间
                    <el-date-picker
                      v-model="ruleForm.time"
                      type="datetime"
                      size="medium"
                      :disabled="
                        ruleForm.price_type == 2 || ruleForm.validity_type != 3
                      "
                      style="width: 225px"
                      class="ml10 mr10"
                      placeholder="选择日期时间"
                      :picker-options="pickerOptions"
                      ref="dateTimer"
                      popper-class="graphic-pick-time"
                    ></el-date-picker>
                    到期
                  </el-radio>
                </p>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
      </el-form-item>

      <el-form-item label="购买按钮名称" :required="true">
        <el-input
          class="name_input2"
          v-model="ruleForm.button_name"
          @blur="
            ruleForm.button_name = ruleForm.button_name.replace(
              /^\s+|\s+$/gm,
              ''
            )
          "
          style="width: 280px"
          size="medium"
          placeholder="输入购买按钮名称"
          show-word-limit
          maxlength="6"
        ></el-input>
      </el-form-item>

      <el-form-item label="" class="norequire">
        <div slot="label" class="flex-center">
          封面文字标识
          <el-popover
            placement="top-start"
            width="419px"
            style="margin-left: 6px"
            trigger="click"
          >
            <template slot="reference">
              <div class="vm help" :class="{ icon2 }"></div>
            </template>
            <img src="@ass/img/1.4.19/img_fmwzbs.png" />
          </el-popover>
        </div>
        <el-input
          class="name_input2"
          v-model="ruleForm.identification"
          @blur="
            ruleForm.identification = ruleForm.identification.replace(
              /^\s+|\s+$/gm,
              ''
            )
          "
          style="width: 280px"
          size="medium"
          placeholder="请输入封面文字标识"
          show-word-limit
          maxlength="4"
        ></el-input>
      </el-form-item>

      <el-form-item label="引导关注/加微信" class="norequire">
        <el-switch
          class="mr10"
          v-model="ruleForm.course_guide_content.status"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        报名后
        <el-button type="text" class="ml20" @click="status = true">
          设置二维码
        </el-button>
      </el-form-item>
    </el-form>

    <div class="submit">
      <el-button
        size="medium"
        style="width: 100px"
        @click="$router.push({ path: '/graphic' })"
      >
        取消
      </el-button>
      <el-button
        size="medium"
        type="primary"
        @click="submit"
        style="width: 100px"
      >
        保存
      </el-button>
    </div>
    <ewm-setting
      v-if="status"
      :dialogstatus.sync="status"
      :course_guide_content="ruleForm.course_guide_content"
      @ewmVal="ewmVal"
    ></ewm-setting>
  </div>
</template>

<script>
import ewmSetting from './ewmSetting'
import huabeiIssue from '@cm/huabeiIssue'
import editor from '@cm/editor'
// import editor from '@cm/editorV5/editorV5'
import changePhotoimg from '@cm/base/changePhotoimg'
// import helpIcon from '@/components/helpIcon'
export default {
  name: '',

  components: {
    changePhotoimg,
    editor,
    huabeiIssue,
    ewmSetting,
    // helpIcon,
  },

  data() {
    return {
      status: false,

      datalist: [],

      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() < Date.now() //设置选择今天以及今天之后的日期
        },
      },

      value1: '',

      icon2: '',

      ruleForm: {
        graphic_id: '',

        name: '',

        cover_img: '',

        details: '',

        trial_details: '',

        price_type: '2',

        validity_type: '1',

        validity: '',

        price: '',

        cost_price: '',

        fee_pwd: '',

        button_name: '立即购买',

        identification: '图文',

        is_installment_3: '2',

        is_installment_6: '2',

        is_installment_12: '2',

        day: '',

        time: '',

        course_guide_content: {
          status: '1',
          content: '长按识别二维码，关注公众号',
          qr_code_url: '',
        },
      },
    }
  },

  computed: {
    isNew() {
      return this.$route.query.id == 'new'
    },

    graphic_id() {
      return this.$route.query.id
    },
  },

  created() {
    if (!this.isNew) {
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '编辑图文'

      this.ruleForm.graphic_id = this.graphic_id
      this.setGraphic()
    }
  },

  methods: {
    ewmVal(val) {
      this.ruleForm = _.assign({}, this.ruleForm, { course_guide_content: val })
    },

    async setGraphic() {
      const { data } = await this.$http({
        url: `/Graphic/setGraphic?graphic_id=${this.graphic_id}`,
      })
      this.ruleForm = _.assign({}, data, {
        day: data.validity_type == 2 ? data.validity : '',
        time: data.validity_type == 3 ? data.validity * 1000 : '',
      })
    },

    // 保存
    async submit() {
      if (!this.ruleForm.name) {
        this.$root.prompt('请输入图文名称')
        return
      }

      if (!this.ruleForm.cover_img) {
        this.$root.prompt('请选择图文封面')
        return
      }

      if (!this.ruleForm.details) {
        this.$root.prompt('请输入图文详情')
        return
      }

      if (!this.ruleForm.button_name) {
        this.$root.prompt('请输入购买按钮名称')
        return
      }

      if (this.ruleForm.price_type == 3 && !this.ruleForm.fee_pwd) {
        this.$root.prompt('请输入报名密码')
        return
      }

      if (this.ruleForm.price_type == 1 && !this.ruleForm.price) {
        this.$root.prompt('请输入价格')
        return
      }

      this.ruleForm = _.assign({}, this.ruleForm, {
        validity:
          this.ruleForm.validity_type == 2
            ? this.ruleForm.day
            : this.ruleForm.validity_type == 3
            ? this.ruleForm.time / 1000
            : '',
      })
      if (this.isNew) {
        await this.$http({
          url: '/Graphic/addGraphic',
          data: this.ruleForm,
          successMsg: true,
        })
      } else {
        await this.$http({
          url: '/Graphic/setGraphic',
          data: this.ruleForm,
          successMsg: true,
        })
      }

      this.$router.push({ path: '/graphic' })
    },

    getPriceValue() {
      this.ruleForm.price = this.$refs.price.value
    },

    getCostPriceValue() {
      this.ruleForm.costprice = this.$refs.costprice.value
    },

    // 图片结果
    complete(val) {
      this.ruleForm.cover_img = val
    },

    // 选择图片
    upload() {
      this.$refs.changePhotoimg.changePicture()
    },
  },
}
</script>

<style lang="scss" scoped>
.newGraphic {
  background-color: #fff;
  padding: 20px;
  .text-nowarp {
    word-break: keep-all;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    position: absolute;
    padding-top: 5px;
  }
  ::v-deep .norequire {
    & .el-form-item__label {
      padding-left: 10px;
    }
  }
  ::v-deep .ruleFormclass {
    .help {
      width: 14px;
      height: 14px;
      cursor: pointer;
      background: url('~@ass/img/1.2.9/ico_wh@2x.png');
      background-size: 100%;
      &.icon2 {
        width: 8px;
        height: 13px;
        background: url('~@ass/img/1.2.9/ico_xljt.png');
        background-size: 100%;
      }
    }
    .editor {
      width: 673px;
    }
    .name_input {
      .el-input__suffix {
        right: -41px;
        top: 10px;
      }
    }

    .name_input2 {
      .el-input__suffix {
        right: -28px;
        top: 10px;
      }
    }
    .changePhotoimg {
      height: 0 !important;
      width: 0 !important;
    }
    .coustom_contain {
      display: flex;
      .tu {
        width: 220px;
        height: 124px;
        background: #f5f5f5;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        .tip {
          font-size: 14px;
          color: #333333;
          margin-bottom: 20px;
          line-height: 1.5;
        }
      }
    }
    .sale_contain {
      width: 633px;
      background-color: #f5f5f5;
      padding: 14px 20px;
      .el-form-item {
        margin-bottom: 20px;
      }
      .el-radio__input.is-checked + .el-radio__label {
        color: inherit;
      }

      .huabei-issue {
        padding: 0 !important;
        .item {
          margin-right: 25px;
        }
      }
    }
  }

  .submit {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
</style>
<style lang="less">
.graphic-pick-time {
  .el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
    display: none;
  }
}
</style>
